import { Component } from '@angular/core';

@Component({
  selector: 'cil-presentation-layout',
  templateUrl: './presentation-layout.component.html',
  styleUrls: ['./presentation-layout.component.scss'],
})
export class PresentationLayoutComponent {
  public commitHash = import.meta.env.NG_APP_COMMIT_HASH;
}
